<script setup>
import AppNavbar from '@/components/Navbar/AppNavbar.vue'
import "@/style/EditView.css"
</script>

<template>
  <div>
    <AppNavbar />
    <div class="container">
      <h1 class="headingText" v-if="user.ID">{{ $t("users.editUser") }} #{{ user.ID }}</h1>
      <h1 class="headingText" v-if="!user.ID">{{ $t("users.addUser") }}</h1>
      <div  v-if="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>
      <div  v-if="success" class="alert alert-success" role="alert">
        {{ success }}
      </div>
      <form class="row g-3" @submit.prevent="submitForm">
        <div class="col-md-6">
          <label for="inputName" class="form-label">{{ $t("users.name") }}*</label>
          <input type="text" class="form-control" id="inputName" v-model="user.Name" required>
        </div>
        <div class="col-12">
          <label for="inputEmail4" class="form-label">{{ $t("users.email") }}*</label>
          <input type="email" class="form-control" id="inputEmail4" v-model="user.Email" required>
        </div>
        <div class="col-12">
          <button type="submit" class="btn button-orange">{{ $t("general.add") }}</button>
        </div>
      </form>

    </div>
  </div>
</template>


<script>
import { getUserByID, createUser, updateUserByID } from '@/apiCalls/User';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  name: 'EditUser',
  data() {
    return {
      t: null,
      router: null,
      id: null,
      error: "",
      success: "",
      user: {
        ID: null,
        Name: '',
        Email: '',
      },
    };
  },  
  async mounted() {
    const { t } = useI18n()
    const route = useRoute();
    const router = useRouter();
    this.t = t;
    this.router = router;
    this.id = route.query.id;
    if (this.id) {
      this.user = await getUserByID(this.id);
    }
  },
  methods: {
    async submitForm() {
      if (!this.user.ID) {
        try {
          await createUser(JSON.stringify(this.user))
          this.success = this.t('alerts.userCreatedSuccessfully')
          setTimeout(() => {
            this.router.push('/admin/users');
          }, 1500)
        } catch (error) {
          if (error.response.data.errorMessage === 'duplicateEntry') {
            this.error = this.t('alerts.duplicateEntry', {entry: this.t('entry.user')})
          } else {
            this.error = error.response.data.errorMessage
          }
          setTimeout(() => {
            this.error = ''
          }, 1500)
        }
      } else {
        try {
          await updateUserByID(this.user.ID, JSON.stringify(this.user))
          this.success = this.t('alerts.userUpdatedSuccessfully')
          setTimeout(() => {
            this.router.push('/admin/users');
          }, 1000)
        } catch (error) {
          this.error = error.response.data.errorMessage
          setTimeout(() => {
            this.error = ''
          }, 1000)
        }
      }
    },
  }
};
</script>