import { defineStore } from "pinia";
import axios from '@/axiosConfig/axiosAuth';

export const useGuestSessionStore = defineStore("guestsession", {
    state: () => ({
        token: "",
        GuestID: "",
        Role: "",
    }),

    getters: {
        isAuthenticated: (state) => {
            return state.token != "";
        },

        isStudent: (state) => {
            return state.Role == "Student";
        },

        isProfessor: (state) => {
            return state.Role == "Professor";
        },

        getGuestID: (state) => {
            return state.GuestID;
        },
        getRole: (state) => {
            return state.Role;
        },
      
    },

    actions: {
        saveLoginState(response) {
            this.token = response.jwt;
            this.GuestID = response.GuestID;
            this.Role = response.Role;

            localStorage["token-guest"] = this.token;
            localStorage["GuestID"] = this.GuestID;
            localStorage["guest-role"] = this.Role;

            axios.defaults.headers.common['Guest-Token'] = 'Bearer ' + this.token;
            
        },
        logout() {
            this.token = "";
            this.GuestID = "";
            this.Role = "";

            localStorage.removeItem("token-guest");
            localStorage.removeItem("GuestID");
            localStorage.removeItem("guest-role");
            axios.defaults.headers.common['Guest-Token'] = 'Bearer ';
        },
    }
});