import { createI18n } from "vue-i18n";

const messages = {
  en: {
    general: {
      yes: "Yes",
      no: "No",
      search: "Search",
      save: "Save",
      note: "Note",
      edit: "Edit",
      add: "Add",
      delete: "Delete",
      orderNumber: "Nr.",
      submit: "Submit",
      selectPlaceholder: "Select...",
      select: "Select",
      selected: "Selected",
      deselect: "Deselect",
      count: "Count",
      close: "Close",
    },
    login: {
      login: "Login",
      toLogIn: "Log in",
      email: "Email",
      name: "Name",
      password: "Password",
      forgotPassword: "I forgot my password"
    },
    nav: {
      home: "Home",
      gallery: "Gallery",
      guests: "Guests",
      allergens: "Allergens",
      dietaryPreferences: "Dietary Preferences",
      food: "food",
      beverage: "beverage",
      hotel: "Hotel",
      overview: "Overview",
      users: "Users",
      logout: 'Logout',
    },
    home: {
      introText: "Reunion after 35 years",
      enterCodePlaceholder: "Code from the invitation",
      search: "Search",
      attendance: "Attendance",
      selectOption: "Select one option",
      willAttend: "I will attend",
      wontAttend: "I won't attend",
      toast: "Toast",
      alcoholic: "Alcoholic",
      nonAlcoholic: "Non-alcoholic",
      food: "Food",
      meaty: "Meaty",
      vegetarian: "Vegetarian",
      hotelRoomReservation: "Hotel room reservation",
      location: "Location",
      date: "Date",
    },
    guests: {
      guests: "Guests",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      attendance: "Attendance",
      payment: "Payment",
      code: "Code",
      hotelRoom: "Hotel room",
      nrOfBeds: "Nr. of beds",
      addGuest: "Add guest",
      editGuest: "Edit guest",
    },
    users: {
      name: "Name",
      email: "Email",
      addUser: "Add user",
      editUser: "Edit user",
    },
    food: {
      addAllergen: 'Add allergen',
      editAllergen: 'Edit allergen',
      addDietaryPreference: 'Add dietary preference',
      editDietaryPreference: 'Edit dietary preference',
      name: "Name",
    },
    hotel: {
      addRoom: "Add room",
      editRoom: "Edit room",
      rooms: "Rooms",
      roomType: "Room type",
      nrOfBeds: "Nr. of beds",
      price: "Price",
    },
    overview: {
      attendance: "Attendance",
      willCome: 'Will come',
      wontCome: "Won't come",
      unconfirmed: "Unconfirmed",
      isPaid: 'Paid',
      allergens: "Allergens",
      dietaryPreference: "Dietary preference",
      summary: "Summary",
      note: "Note",
      orders: "Orders",
      alcoholicToast: "Alcoholic toast",
      toastPreference: "Toast preference",
      alcoholic: "Alcoholic",
      nonAlcoholic: "Non-alcoholic",
    },
    roles: {
      role: "Role",
      student: "Student",
      professor: "Professor",
      admin: "Admin",
      owner: "Owner",
    },
    alerts: {
      guestCreatedSuccessfully: "New guest has been created!",
      guestUpdatedSuccessfully: "The guest has been updated!",
      guestDeleteSuccessfully: "The guest has been deleted!",
      confirmationToDelete: "Are you sure you want to delete: {firstName} {lastName}?",
      dietaryPreferenceCreatedSuccessfully: "New preference has been created!",
      dietaryPreferenceUpdatedSuccessfully: "The preference has been updated!",
      dietaryPreferenceDeletedSuccessfully: "The preference has been deleted!",
      allergenCreatedSuccessfully: "New allergen has been created!",
      allergenUpdatedSuccessfully: "The allergen has been updated!",
      allergenDeletedSuccessfully: "The allergen has been deleted!",
      roomCreatedSuccessfully: "New hotel room has been created!",
      roomUpdatedSuccessfully: "The hotel room has been updated!",
      roomDeletedSuccessfully: "The hotel room has been deleted!",
      duplicateEntry: "{entry} already exists!",
      notFound: "{entry} was not found!",
      passwordResetedSuccessfully: "Password has been updated!",
      changesSaved: "Changes has been saved!",    
      paymentReceived: "Payment received.",
      selectHotelRoomOption: "Please select the hotel room.",
      selectOption: "Please select one of these options.",
      emailToResetPasswordSent: "Instructions to reset password has been sent to your email.",
      enterEmail: "Enter email",
    },
    entry: {
      dietaryPreference: 'Dietary preference',
      allergens: "Allergen",
      hotelRoom: "Hotel room",
      user: 'User',
    },
    validation: {
      resetPassword: "Reset password",
      newPassword: "New password",
      repeatPassword: "Repeat password",
    }
    
  }, 
  sk: {
    general: {
      yes: "Áno",
      no: "Nie",
      search: "Hľadať",
      save: "Uložiť",
      note: "Poznámka",
      edit: "Upraviť",
      add: "Pridať",
      delete: "Vymazať",
      orderNumber: "P.č",
      submit: "Odoslať",
      selectPlaceholder: "Vyber možnosť...",
      select: "Vyber",
      selected: "Vybrané",
      deselect: "Zruš výber",
      count: "Počet",
      close: "Zatvoriť",

    },
    login: {
      login: "Prihlásenie",
      toLogIn: "Prihlásiť sa",
      email: "Email",
      name: "Meno",
      password: "Heslo",
      forgotPassword: "Zabudol som heslo"
    },
    nav: {
      home: "Domov",
      gallery: "Galéria",
      guests: "Hostia",
      allergens: "Alergény",
      dietaryPreferences: "Diétne preferencie",
      food: "Jedlá",
      beverage:  "Nápoje",
      hotel: "Hotel",
      overview: "Prehľad",
      users: "Používatelia",
      logout: 'Odhlásiť sa',
    },
    home: {
      introText: "Stretnutie po 35 rokoch",
      enterCodePlaceholder: "Kód z pozvánky",
      search: "Hľadať",
      attendance: "Účasť",
      selectOption: "Vyber možnosť",
      willAttend: "Prídem",
      wontAttend: "Neprídem",
      toast: "Prípitok",
      alcoholic: "Alkoholický",
      nonAlcoholic: "Nealkoholický",
      food: "Jedlo",
      meaty: "Mäsité",
      vegetarian: "Vegetariánske",
      hotelRoomReservation: "Rezervácia hotelovej izby",
      location: "Miesto",
      date: "Dátum",
    },
    guests: {
      guests: "Hostia",
      firstName: "Meno",
      lastName: "Priezvisko",
      email: "Email",
      attendance: "Účasť",
      payment: "Platba",
      code: "Kód",
      hotelRoom: "Hotelová izba",
      nrOfBeds: "Počet lôžok",
      addGuest: "Pridať hosťa",
      editGuest: "Upraviť hosťa",
    },
    users: {
      name: "Meno",
      email: "Email",
      addUser: "Pridať používateľa",
      editUser: "Upraviť používateľa",
    },
    food: {
      addAllergen: 'Pridať alergén',
      editAllergen: 'Upraviť alergén',
      addDietaryPreference: 'Pridať diétnu preferenciu',
      editDietaryPreference: 'Upraviť diétnu preferenciu',
      name: "Meno",
    },
    hotel: {
      addRoom: "Pridať izbu",
      editRoom: "Upraviť izbu",
      rooms: "Izby",
      roomType: "Typ izby",
      nrOfBeds: "Počet lôžok",
      price: "Cena",
    },
    overview: {
      attendance: "Účasť",
      willCome: 'Prídu',
      wontCome: "Neprídu",
      unconfirmed: "Nepotvrdené",
      isPaid: 'Zaplatené',
      allergens: "Alergény",
      dietaryPreference: "Typ jedla",
      summary: "Súhrn",
      note: "Poznámka",
      orders: "Objednávky",
      alcoholicToast: "Alkoholický prípitok",
      toastPreference: "Typ prípitku",
      alcoholic: "Alkoholický",
      nonAlcoholic: "Nealkoholický",
    },
    roles: {
      role: "Rola",
      student: "Študent",
      professor: "Profesor",
      admin: "Admin",
      owner: "Vlastník",
    },
    alerts: {
      guestCreatedSuccessfully: "Nový hosť bol úspešne vytvorený!",
      guestUpdatedSuccessfully: "Hosť bol úspešne upravený!",
      guestDeletedSuccessfully: "Hosť bol úspešne odstránený!",
      userCreatedSuccessfully: "Nový používateľ bol úspešne vytvorený!",
      userUpdatedSuccessfully: "Používateľ bol úspešne upravený!",
      userDeletedSuccessfully: "Používateľ bol úspešne odstránený!",
      confirmationToDelete: "Skutočne si želáte vymazať: {firstName} {lastName}?",
      dietaryPreferenceCreatedSuccessfully: "Nová preferencia bola úspešne vytvorená!",
      dietaryPreferenceUpdatedSuccessfully: "Preferencia bola úspešne upravená!",
      dietaryPreferenceDeletedSuccessfully: "Preferencia bola úspešne odstránená!",
      allergenCreatedSuccessfully: "Nový alergén bol úspešne vytvorený!",
      allergenUpdatedSuccessfully: "Alergén bol úspešne upravený!",
      allergenDeletedSuccessfully: "Alergén bol úspešne odstránený!",
      roomCreatedSuccessfully: "Nová hotelová izba bola úspešne vytvorená!",
      roomUpdatedSuccessfully: "Hotelová izba bola úspešne upravená!",
      roomDeletedSuccessfully: "Hotelová izba bola úspešne odstránená!",
      duplicateEntry: "{entry} s takouto hodnotou už existuje.",
      notFound: "{entry} nebol nájdený!",
      passwordResetedSuccessfully: "Heslo bolo úspešne obnovené",
      changesSaved: "Zmeny boli uložené!",
      paymentReceived: "Platba bola prijatá.",
      selectHotelRoomOption: "Prosím vyberte si hotelovú izbu.",
      selectOption: "Prosím vyberte jednu z možností.",
      emailToResetPasswordSent: "Inštrukcie na obnovu hesla boli zaslané na Váš email.",
      enterEmail: "Zadajte email",

    },
    entry: {
      dietaryPreference: 'Diétna preferencia',
      allergen: "Alergén",
      hotelRoom: "Hotelová izba",
      user: 'Používateľ',
    },
    validation: {
      resetPassword: "Obnova hesla",
      newPassword: "Nové heslo",
      repeatPassword: "Zopakuj heslo",
    }
  }
}



const i18n = createI18n({
  locale: 'sk',
  fallbackLocale: 'sk', 
  legacy: false, 
  messages
})


export default i18n
