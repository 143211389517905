import axios from '@/axiosConfig/axiosAuth';
export const getAllergens = async () => {
    const url = `/allergens`;
    const response = await axios.get(url);
    return response.data;
  };

  export const getAllergenByID = async (id) => {
    const url = `/allergens/${id}`;
    const response = await axios.get(url);
    return response.data;
  };

  export const deleteAllergen = async (id) => {
    const url = `/allergens/${id}`;
    const response = await axios.delete(url);
    return response.data;
  };


  export const updateAllergenByID = async (id, data) => {
    const url = `/allergens/${id}`;
    const response = await axios.put(url, data);
    return response.data;
  };

  export const createAllergen = async (data) => {
    const url = `/allergens/`;
    const response = await axios.post(url, data);
    return response.data;
  };
