<script setup>
import AppNavbar from '@/components/Navbar/AppNavbar.vue'
import "@/style/EditView.css"
</script>

<template>
  <div>
    <AppNavbar />
    <div class="container">
      <h1 class="headingText" v-if="allergen.ID">{{ $t("food.editAllergen") }} #{{ allergen.ID }}</h1>
      <h1 class="headingText" v-if="!allergen.ID">{{ $t("food.addAllergen") }}</h1>
      <div  v-if="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>
      <div  v-if="success" class="alert alert-success" role="alert">
        {{ success }}
      </div>
      <form class="row g-3" @submit.prevent="submitForm">
        <div class="col-12">
          <label for="inputAllergenName" class="form-label">{{ $t("food.name") }}*</label>
          <input type="text" class="form-control" id="inputAllergenName" v-model="allergen.Name" required>
        </div>
        <div class="col-12">
          <button type="submit" class="btn button-orange">{{ $t("general.add") }}</button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import { getAllergenByID, createAllergen, updateAllergenByID } from '@/apiCalls/Allergens';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  name: 'EditAllergen',
  data() {
    return {
      t: null,
      router: null,
      id: null,
      error: "",
      success: "",
      allergen: {
        ID: null,
        Name: '',
      },
    };
  },  
  async mounted() {
    const { t } = useI18n()
    const route = useRoute();
    const router = useRouter();
    this.t = t;
    this.router = router;
    this.id = route.query.id;
    if (this.id) {
      this.allergen = await getAllergenByID(this.id);
    }
  },
  methods: {
    async submitForm() {
      if (!this.allergen.ID) {
        try {
          await createAllergen(JSON.stringify(this.allergen))
          this.success = this.t('alerts.allergenCreatedSuccessfully')
          setTimeout(() => {
            this.router.push('/admin/food');
          }, 1000)
        } catch (error) {
          if (error.response.data.errorMessage === 'duplicateEntry') {
            this.error = this.t('alerts.duplicateEntry', {entry: this.t('entry.allergen')})
          } else {
            this.error = error.response.data.errorMessage
          }
          setTimeout(() => {
            this.error = ''
          }, 1000)
        }
      } else {
        try {
          await updateAllergenByID(this.allergen.ID, JSON.stringify(this.allergen))
          this.success = this.t('alerts.allergenUpdatedSuccessfully')
          setTimeout(() => {
            this.router.push('/admin/food');
          }, 1000)
        } catch (error) {
          if (error.response.data.errorMessage === 'duplicateEntry') {
            this.error = this.t('alerts.duplicateEntry', {entry: this.t('entry.allergen')})
          } else {
            this.error = error.response.data.errorMessage
          }
          setTimeout(() => {
            this.error = ''
          }, 1000)
        }
      }
    },
  }
};
</script>