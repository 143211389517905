import axios from '@/axiosConfig/axiosAuth';
export const getGuests = async () => {

    const url = `/guests`;
    const response = await axios.get(url);
    return response.data;
  };

  export const getGuestsWithAllDetails = async () => {

    const url = `/guests/detail`;
    const response = await axios.get(url);
    return response.data;
  };


  export const getGuestByID = async (id) => {
    const url = `/guests/${id}`;
    const response = await axios.get(url);
    return response.data;
  };

  export const getGuestByCode = async (data) => {
    const url = `/guests/code`;
    const response = await axios.post(url, data);
    return response.data;
  };

  export const updateGuestByID = async (id, data) => {
    const url = `/guests/${id}`;
    const response = await axios.put(url, data);
    return response.data;
  };

  export const updateGuestPreferencesByID = async (id, data) => {
    const url = `/guests/preferences/${id}`;
    const response = await axios.put(url, data);
    return response.data;
  };

  export const createGuest = async (data) => {
    const url = `/guests/`;
    const response = await axios.post(url, data);
    return response.data;
  };

  export const deleteGuest = async (id) => {
    const url = `/guests/${id}`;
    const response = await axios.delete(url);
    return response.data;
  };
