import { defineStore } from "pinia";
import axios from '@/axiosConfig/axiosAuth';
import {jwtDecode} from 'jwt-decode';

export const useUserSessionStore = defineStore("usersession", {
    state: () => ({
        token: "",
        UserID: "",
        Email: "",
        Name: "",
        Role: "",
    }),

    getters: {
        isAuthenticated: (state) => {
            return state.token != "";
        },

        isAdmin: (state) => {
            return state.Role == "Admin";
        },

        getUserID: (state) => {
            return state.UserID;
        },
        getEmail: (state) => {
            return state.Email;
        },
        getName: (state) => {
            return state.Name;
        },

        getRole: (state) => {
            return state.Role;
        },
      
    },

    actions: {
        async localLogin() {
            const token = localStorage.getItem("token");
            if (token && !this.isTokenExpired(token)) {
                this.token = localStorage["token"];
                this.Name = localStorage["Name"];
                this.UserID = localStorage["UserID"];
                this.Email = localStorage["Email"];
                this.Role = localStorage["Role"];
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
            } else {
                this.logout(); // Clear the expired token
            }
        },

        saveLoginState(response) {
            try {
                this.token = response.jwt;
                this.Name = response.Name;
                this.UserID = response.UserID;
                this.Email = response.Email;
                this.Role = response.Role;
        
                localStorage.setItem("token", this.token);
                localStorage.setItem("Name", this.Name);
                localStorage.setItem("UserID", this.UserID);
                localStorage.setItem("Email", this.Email);
                localStorage.setItem("Role", this.Role);
        
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
            } catch (error) {
                console.error("Error in saveLoginState:", error);
                if (error.response) {
                    console.error("Response error:", error.response.data);
                } else if (error.request) {
                    console.error("Request error:", error.request);
                } else {
                    console.error("Error:", error.message);
                }
            }
        },
        logout() {
            this.token = "";
            this.Name = "";
            this.UserID = "";
            this.Email = "";
            this.Role = "";

            localStorage.removeItem("token");
            localStorage.removeItem("UserID");
            localStorage.removeItem("Name");
            localStorage.removeItem("Email");
            localStorage.removeItem("Role");
            axios.defaults.headers.common['Authorization'] = 'Bearer ';
        },
        isTokenExpired(token) {
            try {
                const decoded = jwtDecode(token);
                const currentTime = Date.now() / 1000; // current time in seconds
                return decoded.exp < currentTime;
            } catch (error) {
                console.error("Error decoding token:", error);
                return true; // Consider token expired if decoding fails
            }
        },
    }
});