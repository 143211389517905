<template>
  <RouterView />
</template>

<script>
import {RouterView} from 'vue-router'
import { useUserSessionStore } from '@/stores/usersessions';
export default {
  setup() {
    return {
      store: useUserSessionStore()
    }
  },
  async mounted() {
    await this.store.localLogin();
  },
  name: "App",
  components: {
    RouterView
  }
};
</script>