<template>
  <a class="btn show-info-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
    <font-awesome-icon :icon="['fas', 'circle-info']" />
  </a>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl event-info">
      <div class="modal-content" >
        <div class="modal-header">
          <h4 class="title">{{ role == 'Professor' ? "Milá triedna učiteľka, milí triedni učitelia!" : "Milá spolužiačka, milý spolužiak!" }}</h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-html="htmlContent">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OverlayInfo",
  props: {
    role: {
      type: String,
      required: true,
    }

  },
  data() {
    return {
      htmlContent: '',
    };
  },
  methods: {
    async loadFile() {
      try {
        const fileUrl = this.role === 'Professor' ? "/files/email-professor-template.html" : "/files/email-student-template.html"
        const response = await fetch(fileUrl);
        if (response.ok) {
          this.htmlContent = await response.text(); // Load HTML content as a string
        } else {
          console.error('Error loading file:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching file:', error);
      }
    },
    
  },

  mounted() {
    this.loadFile();
  }
};
</script>