<script setup>
import AppNavbar from '@/components/Navbar/AppNavbar.vue'
import "@/style/EditView.css"
</script>

<template>
  <div>
    <AppNavbar />
    <div class="container">
      <h1 class="headingText" v-if="guest.ID">{{ $t("guests.editGuest") }} #{{ guest.ID }}</h1>
      <h1 class="headingText" v-if="!guest.ID">{{ $t("guests.addGuest") }}</h1>
      <div  v-if="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>
      <div  v-if="success" class="alert alert-success" role="alert">
        {{ success }}
      </div>
      <form class="row g-3" @submit.prevent="submitForm">
        <div class="col-md-6">
          <label for="inputFirstName" class="form-label">{{ $t("guests.firstName") }}*</label>
          <input type="text" class="form-control" id="inputFirstName" v-model="guest.FirstName" required>
        </div>
        <div class="col-md-6">
          <label for="inputLastName" class="form-label">{{ $t("guests.lastName") }}*</label>
          <input type="text" class="form-control" id="inputLastName" v-model="guest.LastName" required>
        </div>
        <div class="col-12">
          <label for="role" class="form-label">{{ $t("roles.role") }}*</label>
          <select class="form-select" v-model="guest.Role" required>
            <option value="Student" selected>{{ $t('roles.student') }}</option>
            <option value="Professor">{{ $t('roles.professor') }}</option>
          </select>
        </div>
        <div class="col-12">
          <label for="inputEmail4" class="form-label">{{ $t("guests.email") }}</label>
          <input type="email" class="form-control" id="inputEmail4" v-model="guest.Email">
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" v-model="guest.IsPaid" id="flexCheckDefault">
          <label class="form-check-label" for="flexCheckDefault">
            {{ $t("overview.isPaid") }}
          </label>
        </div>
        <div class="col-12">
          <button type="submit" class="btn button-orange">{{ guest.ID ? $t("general.edit") : $t("general.add") }}</button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import { getGuestByID, createGuest, updateGuestByID } from '@/apiCalls/Guest';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  name: 'EditGuest',
  data() {
    return {
      t: null,
      router: null,
      id: null,
      error: "",
      success: "",
      guest: {
        ID: null,
        FirstName: '',
        LastName: '',
        Email: '',
        Role: 'Student',
        IsPaid: false,
      },
    };
  },  
  async mounted() {
    const { t } = useI18n()
    const route = useRoute();
    const router = useRouter();
    this.t = t;
    this.router = router;
    this.id = route.query.id;
    if (this.id) {
      this.guest = await getGuestByID(this.id);
    }
  },
  methods: {
    async submitForm() {
      if (!this.guest.ID) {
        try {
          await createGuest(JSON.stringify(this.guest))
          this.success = this.t('alerts.guestCreatedSuccessfully')
          setTimeout(() => {
            this.router.push('/admin/guests');
          }, 1000)
        } catch (error) {
          this.error = error.response.data.errorMessage
          setTimeout(() => {
            this.error = ''
          }, 1000)
        }
      } else {
        try {
          await updateGuestByID(this.guest.ID, JSON.stringify(this.guest))
          this.success = this.t('alerts.guestUpdatedSuccessfully')
          setTimeout(() => {
            this.router.push('/admin/guests');
          }, 1000)
        } catch (error) {
          this.error = error.response.data.errorMessage
          setTimeout(() => {
            this.error = ''
          }, 1000)
        }
      }
    },
  }
};
</script>