import axios from 'axios';
// eslint-disable-next-line 
const baseURL = "https://backend-event-management.flexi.sk"
// eslint-disable-next-line 
// const baseLocalURL = "http://localhost:80"

const instance = axios.create({
    baseURL: baseURL,
});

instance.interceptors.request.use(
  function (config) {
    config.headers["Content-Type"] = "application/x-www-form-urlencoded"
    config.headers["Accept"] = "application/json";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
