<script setup>
import AppNavbar from '@/components/Navbar/AppNavbar.vue'
import "@/style/Overview.css"
</script>

<template>
  <div>
    <AppNavbar />
    <div class="container">
      <h1 class="headingText">{{ $t("nav.overview") }}</h1>
      <div v-if="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>
      <div v-if="success" class="alert alert-success" role="alert">
        {{ success }}
      </div>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a :class="['nav-link',
            {
              active: $route.hash === '#will-come' || !$route.hash,
              inactiveTab: $route.hash && $route.hash !== '#will-come',
            }]" aria-current="page" href="#will-come">{{ $t("overview.willCome") }} ({{ guestWillAttend.length }}) </a>
        </li>
        <li class="nav-item">
          <a :class="['nav-link',
            {
              active: $route.hash === '#wont-come',
              inactiveTab: $route.hash !== '#wont-come',

            }]" href="#wont-come">{{ $t("overview.wontCome") }} ({{ guestWontAttend.length }}) </a>
        </li>
        <li class="nav-item">
          <a :class="['nav-link',
            {
              active: $route.hash === '#unconfirmed',
              inactiveTab: $route.hash !== '#unconfirmed',
            }]" href="#unconfirmed">{{ $t("overview.unconfirmed") }} ({{ guestUnconfirmed.length }}) </a>
        </li>
        <li>
          <a :class="['nav-link',
            {
              active: $route.hash === '#orders',
              inactiveTab: $route.hash !== '#orders',
            }]" aria-current="page" href="#orders">{{ $t("overview.orders") }}</a>
        </li>
      </ul>

      <div class="table-container" v-if="$route.hash === '#will-come' || !$route.hash">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th scope="col">{{ $t("general.orderNumber") }}</th>
              <th scope="col">{{ $t("guests.firstName") }}</th>
              <th scope="col">{{ $t("guests.lastName") }}</th>
              <th scope="col">{{ $t("roles.role") }}</th>
              <th scope="col">{{ $t("home.hotelRoomReservation") }} ({{ nrOfHotelRooms }})</th>
              <th scope="col">{{ $t("overview.allergens") }}</th>
              <th scope="col">{{ $t("overview.dietaryPreference") }}</th>
              <th scope="col">{{ $t("overview.isPaid") }} ({{ nrOfPaid }})</th>
              <th scope="col">{{ $t("overview.alcoholicToast") }}</th>
              <th scope="col">{{ $t("overview.note") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(guest, index) in guestWillAttend" :key="guest.ID">
              <td>
                <h6 class="mb-0 text-xs">
                  {{ index + 1 }}
                </h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">
                  {{ guest.FirstName }}
                </h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">
                  {{ guest.LastName }}
                </h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">
                  {{ $t(`roles.${guest.Role.toLowerCase()}`) }}
                </h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">
                  {{ guest?.HotelRoom?.RoomType ? guest.HotelRoom.RoomType + ' (' + guest.HotelRoom.Price + '€)' :
                    $t('general.no') }}
                </h6>
              </td>
              <td>
                <ul class="list-disc pl-4 text-xs">
                  <li v-for="allergen in guest.Allergens" :key="allergen.ID">
                    {{ allergen.Name }}
                  </li>
                </ul>
              </td>
              <td>
                <h6 class="mb-0 text-xs">
                  {{ guest.DietaryPreference.Name }}
                </h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">
                  {{ guest.IsPaid ? $t('general.yes') : $t('general.no') }}
                </h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">
                  {{ guest.IsBeverageAlcoholic ? $t('general.yes') : $t('general.no') }}
                </h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">
                  {{ guest.Note }}
                </h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-container" v-if="$route.hash === '#wont-come'">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th scope="col">{{ $t("general.orderNumber") }}</th>
              <th scope="col">{{ $t("guests.firstName") }}</th>
              <th scope="col">{{ $t("guests.lastName") }}</th>
              <th scope="col">{{ $t("roles.role") }}</th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="(guest, index) in guestWontAttend" :key="guest.ID">
              <td>
                <h6 class="mb-0 text-xs">
                  {{ index + 1 }}
                </h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">
                  {{ guest.FirstName }}
                </h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">
                  {{ guest.LastName }}
                </h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">
                  {{ $t(`roles.${guest.Role.toLowerCase()}`) }}
                </h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-container" v-if="$route.hash === '#unconfirmed'">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th scope="col">{{ $t("general.orderNumber") }}</th>
              <th scope="col">{{ $t("guests.firstName") }}</th>
              <th scope="col">{{ $t("guests.lastName") }}</th>
              <th scope="col">{{ $t("roles.role") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(guest, index) in guestUnconfirmed" :key="guest.ID">
              <td>
                <h6 class="mb-0 text-xs">
                  {{ index + 1 }}
                </h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">
                  {{ guest.FirstName }}
                </h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">
                  {{ guest.LastName }}
                </h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">
                  {{ $t(`roles.${guest.Role.toLowerCase()}`) }}
                </h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-container" v-if="$route.hash === '#orders'">
        <h4 class="headingText margin-top">{{ $t("nav.dietaryPreferences") }}</h4>
        <table class="table">
          <thead class="table-light">
            <tr>
              <th scope="col">{{ $t("overview.dietaryPreference") }}</th>
              <th scope="col">{{ $t("general.count") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(count, preference) in dietaryCounts" :key="preference">
              <td>
                <h6 class="mb-0 text-xs">{{ preference }}</h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">{{ count }}</h6>
              </td>
            </tr>
          </tbody>
        </table>

        <h4 class="headingText margin-top">{{ $t("nav.allergens") }}</h4>
        <table class="table">
          <thead class="table-light">
            <tr>
              <th scope="col">{{ $t("overview.dietaryPreference") }}</th>
              <th scope="col">{{ $t("general.count") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(count, allergen) in allergenCounts" :key="allergen">
              <td v-if="allergen != ''">
                <h6 class="mb-0 text-xs">{{ allergen }}</h6>
              </td>
              <td v-if="allergen != ''">
                <h6 class="mb-0 text-xs">{{ count }}</h6>
              </td>
            </tr>
          </tbody>
        </table>

        <h4 class="headingText margin-top">{{ $t("nav.hotel") }}</h4>
        <table class="table">
          <thead class="table-light">
            <tr>
              <th scope="col">{{ $t("entry.hotelRoom") }}</th>
              <th scope="col">{{ $t("general.count") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(count, room) in hotelRoomCounts" :key="room">
              <td>
                <h6 class="mb-0 text-xs">{{ room }}</h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">{{ count }}</h6>
              </td>
            </tr>
          </tbody>
        </table>

        <h4 class="headingText margin-top">{{ $t("nav.beverage") }}</h4>
        <table class="table">
          <thead class="table-light">
            <tr>
              <th scope="col">{{ $t("overview.toastPreference") }}</th>
              <th scope="col">{{ $t("general.count") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h6 class="mb-0 text-xs">{{ $t("overview.alcoholic") }}</h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">{{ nrOfAlcoholicBeverage }}</h6>
              </td>
            </tr>
            <tr>
              <td>
                <h6 class="mb-0 text-xs">{{ $t("overview.nonAlcoholic") }}</h6>
              </td>
              <td>
                <h6 class="mb-0 text-xs">{{ nrOfNonAlcoholicBeverage }}</h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { getGuestsWithAllDetails } from '@/apiCalls/Guest';
import { useI18n } from 'vue-i18n';

export default {
  name: "OverviewView",
  data() {
    return {
      t: null,
      error: "",
      success: "",
      guests: [],
      guestWillAttend: [],
      guestWontAttend: [],
      guestUnconfirmed: [],
      nrOfHotelRooms: 0,
      nrOfPaid: 0,
      nrOfAlcoholicBeverage: 0,
      nrOfNonAlcoholicBeverage: 0,
      dietaryCounts: null,
      allergenCounts: null,
      hotelRoomCounts: null,
    };

  },


  async mounted() {
    try {
      const { t } = useI18n()
      this.t = t;

      this.guests = await getGuestsWithAllDetails()
      this.guestWillAttend = this.guests.filter(guest => guest.Attendance === true);
      this.guestWontAttend = this.guests.filter(guest => guest.Attendance === false);
      this.guestUnconfirmed = this.guests.filter(guest => guest.Attendance === null);
      this.nrOfPaid = this.guestWillAttend.filter(guest => guest.IsPaid === true).length;
      this.nrOfHotelRooms = this.guestWillAttend.filter(guest => guest.HotelRoom != null).length;
      this.nrOfAlcoholicBeverage = this.guestWillAttend.filter(guest => guest.IsBeverageAlcoholic === true).length;
      this.nrOfNonAlcoholicBeverage = this.guestWillAttend.filter(guest => guest.IsBeverageAlcoholic === false).length;

      this.dietaryCounts = this.getCounts(this.guestWillAttend, 'DietaryPreference', [{ "key": 'Name', type: "field" }]);
      this.allergenCounts = this.getCounts(this.guestWillAttend, 'Allergens', [{ "key": 'Name', type: "field" }]);
      this.hotelRoomCounts = this.getCounts(this.guestWillAttend, 'HotelRoom', [{ "key": 'RoomType', type: "field" }, { "key":  ` - ${this.t("hotel.nrOfBeds")}:`, type: 'string' }, { "key": 'MaxOccupacy', type: "field" }, { "key":  `-`, type: 'string' }, { "key": 'Price', type: "field" }, { "key":  `€`, type: 'string' }]);
      console.log(this.guestWillAttend)
    } catch (error) { 
      this.error = error.response.data.errorMessage
      setTimeout(() => {
        this.error = ''
      }, 1000)
    }
  },
  methods: {
    getCounts(guests, fieldName, keys) {
      // Initialize an empty object to store the counts
      const counts = {};

      const getCombinedValue = (obj, keys) => {
        return keys.map(item => {
          if (item.type === "field") {
            return obj[item.key];  // Retrieve value from object
          } else if (item.type === "string") {
            return item.key;  // Use the string directly
          }
          return '';  // Return empty string for unsupported types (optional)
        }).filter(Boolean).join(' ');
      };
      // Iterate over the guests array
      guests.forEach(guest => {
        const fieldValue = guest[fieldName]
        if (!fieldValue) {
          return
        }
        // Check if the fieldValue is an array
        if (Array.isArray(fieldValue)) {
          // If it's an array, loop through each item and count them
          fieldValue.forEach(item => {
            const itemName = getCombinedValue(item, keys);
            // If the item already exists in the counts object, increment its count
            if (counts[itemName]) {
              counts[itemName]++;
            } else {
              // Otherwise, initialize the count to 1
              counts[itemName] = 1;
            }
          });
        } else {
          // If it's not an array, treat it as a single item and count it
          const itemName = getCombinedValue(fieldValue, keys);

          // If the item already exists in the counts object, increment its count
          if (counts[itemName]) {
            counts[itemName]++;
          } else {
            // Otherwise, initialize the count to 1
            counts[itemName] = 1;
          }
        }
      });
      return counts;
    }

  }
}
</script>
