import { createApp } from 'vue'
import { createPinia } from 'pinia'
import 'bootstrap/dist/css/bootstrap.css' 
import VueCookies from 'vue-cookies'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css';

import App from '@/pages/App.vue'
import router from '@/router/index'
import i18n from "./i18n" 

// import './assets/main.css'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUser, faPenToSquare, faX, faAngleUp, faCircleInfo , faCircleXmark} from '@fortawesome/free-solid-svg-icons'

import VueLazyload from 'vue-lazyload'

/* add icons to the library */
library.add(faUser, faPenToSquare, faX, faAngleUp, faCircleInfo, faCircleXmark)
const app = createApp(App)
app.use(i18n);

app.use(VueLazyload, {
    preLoad: 1.3,
    error: '/src/assets/images/error-image.png',
    loading: '/src/assets/images/loading-image.png',
    attempt: 1
  })
  
// const loader = createApp(Loader);
// loader.mount('#loader');

app.use(createPinia())
app.use(router)
app.use(VueCookies)
app.use(VueTelInput);
// app.component('Loader', Loader);
app.component('font-awesome-icon', FontAwesomeIcon)
app.mount('#app')



import 'bootstrap/dist/js/bootstrap.js' 