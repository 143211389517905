<template>
    <div>
      <VueMultiselect
        :model-value="modelValue"
        :multiple="false"
        :options="options"
        :label="label"
        :track-by="value"
        @update:model-value="updateSelected" 
        :placeholder="placeholder"
        :select-label="select"
        :deselect-label="deselect"
        :selected-label="selected"
        :disabled="isDisabled"
      />
    </div>
  </template>
  
  <script>
  import VueMultiselect from 'vue-multiselect';
  import { useI18n } from 'vue-i18n';

  export default {
    components: { VueMultiselect },
    props: {
      modelValue: {
        type: Array, // Ensure the type matches the expected data type
        default: () => []
      },
      options: {
        type: Array, // Ensure the type matches the expected data type
        default: () => []
      },
      label: {
        type: String,
        default: 'label'
      },
      value: {
        type: String,
        default: 'value'
      },
      isDisabled: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        t: null,
        placeholder: 'Select...',
        select: 'Select',
        deselect: 'Deselect',
        selected: 'Selected',
      }
    },
    methods: {
      updateSelected(newValue) {
        this.$emit('update:modelValue', newValue); // Emit the value to update the parent
      }
    },
    
    async mounted() {
      const { t } = useI18n()
      this.t = t; 
      this.placeholder = this.t('general.selectPlaceholder')
      this.select = this.t('general.select')
      this.deselect = this.t('general.deselect')
      this.selected = this.t('general.selected')
    },
    
  }
  </script>
  
  <style src="vue-multiselect/dist/vue-multiselect.css"></style>
  