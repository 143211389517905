import axios from '@/axiosConfig/axiosAuth';
export const getDietaryPreferences = async () => {

    const url = `/dietary-preferences`;
    const response = await axios.get(url);
    return response.data;
  };

  export const getDietaryPreferenceByID = async (id) => {
    const url = `/dietary-preferences/${id}`;
    const response = await axios.get(url);
    return response.data;
  };

  export const deleteDietaryPreference = async (id) => {
    const url = `/dietary-preferences/${id}`;
    const response = await axios.delete(url);
    return response.data;
  };


  export const updateDietaryPreferenceByID = async (id, data) => {
    const url = `/dietary-preferences/${id}`;
    const response = await axios.put(url, data);
    return response.data;
  };

  export const createDietaryPreference = async (data) => {
    const url = `/dietary-preferences/`;
    const response = await axios.post(url, data);
    return response.data;
  };

  
  