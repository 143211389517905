<script setup>
import "./Navbar.css"
</script>
<template>
    <nav class="navbar navbar-expand-lg styledNav">
        <div class="container-fluid navItems">
          <a :class="['navbar-brand', { active: currentUrl === '/' }]" href="/">{{ $t("nav.home") }}</a>
          <a :class="['navbar-brand', { active: currentUrl === '/gallery' }]" href="/gallery">{{ $t("nav.gallery") }}</a>
          <a v-if="store?.isAdmin"  :class="['navbar-brand', { active: currentUrl === '/admin/guests' }]" href="/admin/guests">{{ $t("nav.guests") }}</a>
          <a v-if="store?.isAdmin"  :class="['navbar-brand', { active: currentUrl === '/admin/food' }]" href="/admin/food">{{ $t("nav.food") }}</a>
          <a v-if="store?.isAdmin"  :class="['navbar-brand', { active: currentUrl === '/admin/hotel-rooms' }]" href="/admin/hotel-rooms">{{ $t("nav.hotel") }}</a>
          <a v-if="store?.isAdmin"  :class="['navbar-brand', { active: currentUrl === '/admin/overview' }]" href="/admin/overview">{{ $t("nav.overview") }}</a>
          <a v-if="store?.isAdmin"  :class="['navbar-brand', { active: currentUrl === '/admin/users' }]" href="/admin/users">{{ $t("nav.users") }}</a>  
          <button v-if="store?.isAuthenticated == true" class="btn nav-btn" type="button" @click="logout">{{ $t("nav.logout") }}</button>
        </div> 
        
      </nav>
</template> 

<script>
import { useUserSessionStore } from "@/stores/usersessions";
export default {
  name: 'AppNavbar',
  data() {
    return {
      currentUrl: this.$route.fullPath,
      store:  useUserSessionStore(),
    };
  },
  methods: {
    async logout() {
        await this.store.logout();
        window.location.reload();
      },
  },
  watch: {
    $route(to) {
      this.currentUrl = to.fullPath;
    },
  },
};
</script>