<script setup>
import "@/style/HomeView.css"
</script>

<template>
  <div>
    <div class="container">
      <div class="horizontal-alignment">
        <h1 class="headingText">{{ $t("nav.allergens") }}</h1>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <a href="/admin/allergens/edit?id=" class="btn button-form-submit" type="button">{{ $t("food.addAllergen")
            }}</a>
        </div>
      </div>
      <div v-if="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>
      <div v-if="success" class="alert alert-success" role="alert">
        {{ success }}
      </div>
      <div class="table-container">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th scope="col">{{ $t("food.name") }}</th>
              <th scope="col">{{ $t("general.edit") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="allergen in allergens" :key="allergen.ID">
              <td>
                <h6 class="mb-0 text-xs">
                  {{ allergen.Name }}
                </h6>
              </td>
              <td class="icons-horizontal-alignment">
                <h6 class="mb-0 text-xs">
                  <a :href="`/admin/allergens/edit?id=${allergen.ID}`"><font-awesome-icon
                      :icon="['fas', 'pen-to-square']" />
                  </a>
                </h6>
                <h6 class="mb-0 text-xs">
                  <a class="btn" :data-bs-toggle="'modal'" :data-bs-target="'#modal-' + allergen.ID">
                    <font-awesome-icon :icon="['fas', 'x']" />
                  </a>
                </h6>

                <!-- Modal -->
                <div class="modal fade" :id="'modal-' + allergen.ID" tabindex="-1" aria-labelledby="modalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body">
                        {{ $t("alerts.confirmationToDelete", { firstName: allergen.Name }) }}
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn button-form-danger" data-bs-dismiss="modal"
                          @click="deleteAllergenById(allergen.ID)">{{
                            $t("general.yes") }}</button>
                        <button type="button" class="btn button-form-submit" data-bs-dismiss="modal">{{ $t("general.no")
                          }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllergens, deleteAllergen } from '@/apiCalls/Allergens';
import { useI18n } from 'vue-i18n';

export default {
  name: "FoodView",
  data() {
    return {
      t: null,
      error: "",
      success: "",
      allergens: [],
    };
  },
  async mounted() {
    const { t } = useI18n()
    this.t = t;
    try {
      this.allergens = await getAllergens()
    } catch (error) {
      this.error = error.response.data.errorMessage
      setTimeout(() => {
        this.error = ''
      }, 1500)
    }
  },
  methods: {
    async deleteAllergenById(id) {
      try {
        await deleteAllergen(id)
        this.success = this.t('alerts.allergenDeletedSuccessfully')
        setTimeout(() => {
          this.success = ''
        }, 1500)
        const indexOfDeletedAllergen = this.allergens.findIndex(allergen => allergen.ID === id);
        this.allergens.splice(indexOfDeletedAllergen, 1)
      } catch (error) {
        this.error = error.response.data.errorMessage
        setTimeout(() => {
          this.error = ''
        }, 1500)
      }
    },
  },
}
</script>
