import axios from '@/axiosConfig/axiosAuth';

  export const login = async (data) => {
    const url = `/login`;
    const response = await axios.post(url, data);
    return response.data;
  };

  export const getUsers = async () => {

    const url = `/users`;
    const response = await axios.get(url);
    return response.data;
  };


  export const getUserByID = async (id) => {
    const url = `/users/${id}`;
    const response = await axios.get(url);
    return response.data;
  };

  export const updateUserByID = async (id, data) => {
    const url = `/users/${id}`;
    const response = await axios.put(url, data);
    return response.data;
  };

  export const createUser = async (data) => {
    const url = `/users/`;
    const response = await axios.post(url, data);
    return response.data;
  };

  export const deleteUser = async (id) => {
    const url = `/users/${id}`;
    const response = await axios.delete(url);
    return response.data;
  };

  export const updatePassword = async (data) => {
    const url = `/users/resetpsswd`;
    const response = await axios.put(url, data);
    return response.data;
  }


  export const sendResetPasswordEmail = async (data) => {
    const url = `/users/resetpsswdemail`;
    const response = await axios.post(url, data);
    return response.data;
  }