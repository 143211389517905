import axios from '@/axiosConfig/axiosAuth';
export const getHotelRooms = async () => {
    const url = `/hotel-rooms`;
    const response = await axios.get(url);
    return response.data;
  };

  export const getHotelRoomByID = async (id) => {
    const url = `/hotel-rooms/${id}`;
    const response = await axios.get(url);
    return response.data;
  };

  export const updateHotelRoomByID = async (id, data) => {
    const url = `/hotel-rooms/${id}`;
    const response = await axios.put(url, data);
    return response.data;
  };

  export const createHotelRoom = async (data) => {
    const url = `/hotel-rooms/`;
    const response = await axios.post(url, data);
    return response.data;
  };

  export const deleteHotelRoom = async (id) => {
    const url = `/hotel-rooms/${id}`;
    const response = await axios.delete(url);
    return response.data;
  };