<script setup>
import AppNavbar from '@/components/Navbar/AppNavbar.vue'
import DietaryPreferences from '@/components/DietaryPreferences/DietaryPreferences.vue'
import Allergens from '@/components/Allergens/Allergens.vue'

import "@/style/Table.css"
</script>

<template>
  <div>
    <AppNavbar />
    <Allergens />
    <DietaryPreferences />
  </div>
</template>

<script>
export default {
  name: "FoodView",
}
</script>
