import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/view/HomeView.vue'
import GuestsView from '@/view/GuestView.vue'
import GuestEditView from '@/view/GuestEditView.vue'
import HotelRoomsView from '@/view/HotelRoomsView.vue'
import HotelRoomEditView from '@/view/HotelRoomEditView.vue'
import FoodView from '@/view/FoodView.vue'
import OverviewView from "@/view/OverviewView.vue"
import UsersView from "@/view/UserView.vue"
import UserEditView from '@/view/UserEditView.vue'
import DietaryPreferenceEditView from '@/view/DietaryPreferenceEditView.vue'
import AllergenEditView from '@/view/AllergenEditView.vue'
import { useUserSessionStore } from '@/stores/usersessions';

// import VueCookies from 'vue-cookies'
// import {hasAccount, isAuthenticated, isEmployee} from '../utilities/Authorization'
// import NotFound from '../view/NotFound.vue';
const BASE_URL = '/'
const router = createRouter({
  // history: createWebHistory(import.meta.env.BASE_URL),
  history: createWebHistory(BASE_URL),
  routes: [
    {
      path: '/',
      name: 'base',
      component: HomeView,
      meta: { requiresAuth: false } // Public route
    }, 
    {
      path: '/gallery',
      name: 'Gallery',
      component: () => import('@/view/GalleryView.vue'),
      meta: { requiresAuth: false } // Public route
    }, 
    {
      path: '/admin/guests',
      name: 'adminGuests',
      component: GuestsView,
      meta: { requiresAuth: true } // Private route
    }, 

    {
      path: '/admin/hotel-rooms',
      name: 'adminHotelRooms',
      component: HotelRoomsView,
      meta: { requiresAuth: true } // Private route
    }, 
    {
      path: '/admin/hotel-room/edit',
      name: 'adminHotelRoomEdit',
      component: HotelRoomEditView,
      meta: { requiresAuth: true } // Private route
    }, 
    {
      path: '/admin/food',
      name: 'adminFood',
      component: FoodView,
      meta: { requiresAuth: true } // Private route
    }, 
    {
      path: '/admin/dietary-preference/edit',
      name: 'dietaryPreferences',
      component: DietaryPreferenceEditView,
      meta: { requiresAuth: true } // Private route
    }, 
    {
      path: '/admin/allergens/edit',
      name: 'allergens',
      component: AllergenEditView,
      meta: { requiresAuth: true } // Private route
    }, 
    {
      path: '/guest/edit',
      name: 'guestEdit',
      component: GuestEditView,
      meta: { requiresAuth: true } // Private route
    }, 
    {
      path: '/admin/overview',
      name: 'adminOverview',
      component: OverviewView,
      meta: { requiresAuth: true } // Private route
    }, 
    {
      path: '/admin/users',
      name: 'adminUsers',
      component: UsersView,
      meta: { requiresAuth: true } // Private route
    }, 
    {
      path: '/user/edit',
      name: 'userEdit',
      component: UserEditView,
      meta: { requiresAuth: true } // Private route
    }, 
    {
      path: '/login',
      name: 'login',
      component: () => import('@/view/LoginView.vue'),
      meta: { requiresAuth: false, prohibitsAuth: true } // Public route
    },
    {
      path: '/validation/resetpsswd',
      name: 'resetpsswd',
      component: () => import('@/view/Validation/ResetPasswordView.vue'),
      meta: { requiresAuth: false, prohibitsAuth: true } // Public route
    },
  ]
})


// Navigation guard
router.beforeEach((to, from, next) => {
  const userSessionStore = useUserSessionStore(); // Access Pinia store
  const isAuthenticated = userSessionStore?.isAuthenticated ?? false;

  // If the route requires authentication and the user is not authenticated
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'login' }); // Redirect to login
  } 
  // If the route prohibits authentication and the user is already authenticated
  else if (to.matched.some(record => record.meta.prohibitsAuth) && isAuthenticated) {
    next({ name: 'base' }); // Redirect to home 
  } 
  else {
    next(); // Proceed to the route
  }
});

// eslint-disable-next-line no-unused-vars
// router.beforeEach((to, from, next) => {
// window.onload = function() {
//     setTimeout(removeLoader, 1000); // Wait for page load plus two seconds
//   };
  
//   function removeLoader() {
//     var loader = document.getElementById("loader");
//     fadeOut(loader, 500, function() {
//       // Fade out complete. Remove the loading div
//       loader.remove(); // Makes page more lightweight
//     });
//   }
  
//   function fadeOut(element, duration, callback) {
//     var opacity = 1;
//     var interval = 50;
//     var delta = interval / duration;
  
//     var fade = function() {
//       opacity -= delta;
//       element.style.opacity = opacity;
  
//       if (opacity <= 0) {
//         clearInterval(fading);
//         if (typeof callback === "function") {
//           callback();
//         }
//       }
//     };
  
//     var fading = setInterval(fade, interval);
//   }
  // if (to.matched.some(record => record.meta.prohibitsAuth) && isAuthenticated(VueCookies.get('token'))) {
  //   next({ path: '/employee/customers' });
  // }

  // if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if the user is authenticated
    // if (!isAuthenticated(VueCookies.get('token'))) {
    //   // Redirect to the login page
    //   next({ path: '/login' });
    // } else {
    //   // Proceed to the protected route
    //   if (to.matched.some(record => record.meta.employeeOnly)) {
    //     if (!isEmployee(VueCookies.get('token'))) {
    //       // Redirect to the login page
    //       next({ path: '/profile' });
    //     }  else {
    //       next();
    //     }
    //   } else if (to.matched.some(record => record.meta.requiresAccount)) {
    //     hasAccount(VueCookies.get('token')).then((result) => {
    //       if(!result) {
    //         next({ path: '/profile' });
    //       } else {
    //         next();
    //       }
    //     })
    //   } else {
    //     next();
    //   }
    // }
//   } else {
//     // Public route, proceed
//     next();
//   }
// });

export default router