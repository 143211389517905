<script setup>
import AppNavbar from '@/components/Navbar/AppNavbar.vue'
import "@/style/EditView.css"
</script>

<template>
  <div>
    <AppNavbar />
    <div class="container">
      <h1 class="headingText" v-if="room.ID">{{ $t("hotel.editRoom") }} #{{ room.ID }}</h1>
      <h1 class="headingText" v-if="!room.ID">{{ $t("hotel.addRoom") }}</h1>
      <div  v-if="error" class="alert alert-danger" role="alert">
        {{ error }}
      </div>
      <div  v-if="success" class="alert alert-success" role="alert">
        {{ success }}
      </div>
      <form class="row g-3" @submit.prevent="submitForm">
        <div class="col-12">
          <label for="inputRoomType" class="form-label">{{ $t("hotel.roomType") }}*</label>
          <input type="text" class="form-control" id="inputRoomType" v-model="room.RoomType" required>
        </div>
        <div class="col-12">
          <label for="inputNrOfBeds" class="form-label">{{ $t("hotel.nrOfBeds") }}*</label>
          <input type="number" class="form-control" id="inputNrOfBeds" v-model="room.MaxOccupacy" required>
        </div>
        <div class="col-12">
          <label for="inputPrice" class="form-label">{{ $t("hotel.price") }}*</label>
          <input type="number" step="0.01" class="form-control" id="inputPrice" v-model="room.Price" required>
        </div>
        <div class="col-12">
          <button type="submit" class="btn button-orange">{{ $t("general.add") }}</button>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import { getHotelRoomByID, createHotelRoom, updateHotelRoomByID } from '@/apiCalls/HotelRooms';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  name: 'EditHotelRoom',
  data() {
    return {
      t: null,
      router: null,
      id: null,
      error: "",
      success: "",
      room: {
        ID: null,
        RoomType: '',
        MaxOccupacy: '',
        Price: null,
      },
    };
  },  
  async mounted() {
    const { t } = useI18n()
    const route = useRoute();
    const router = useRouter();
    this.t = t;
    this.router = router;
    this.id = route.query.id;
    if (this.id) {
      this.room = await getHotelRoomByID(this.id);
    }
  },
  methods: {
    async submitForm() {
      if (!this.room.ID) {
        try {
          await createHotelRoom(JSON.stringify(this.room))
          this.success = this.t('alerts.roomCreatedSuccessfully')
          setTimeout(() => {
            this.router.push('/admin/hotel-rooms');
          }, 1000)
        } catch (error) {
          if (error.response.data.errorMessage === 'duplicateEntry') {
            this.error = this.t('alerts.duplicateEntry', {entry: this.t('entry.hotelRoom')})
          } else {
            this.error = error.response.data.errorMessage
          }
          setTimeout(() => {
            this.error = ''
          }, 1000)
        }
      } else {
        try {
          await updateHotelRoomByID(this.room.ID, JSON.stringify(this.room))
          this.success = this.t('alerts.roomUpdatedSuccessfully')
          setTimeout(() => {
            this.router.push('/admin/hotel-rooms');
          }, 1000)
        } catch (error) {
          if (error.response.data.errorMessage === 'duplicateEntry') {
            this.error = this.t('alerts.duplicateEntry', {entry: this.t('entry.hotelRoom')})
          } else {
            this.error = error.response.data.errorMessage
          }
          setTimeout(() => {
            this.error = ''
          }, 1000)
        }
      }
    },
  }
};
</script>