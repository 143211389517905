<script setup>
import "@/style/HomeView.css"
</script>

<template>
  <div ref="mainSection">
    <AppNavbar />
    <div class="background">
      <form class="introForm" @submit.prevent="submitCode">
        <h1 class="introText">{{ $t("home.introText") }}</h1>
        <div v-if="codeError" class="alert alert-danger" role="alert">
          {{ codeError }}
        </div>
        <input type="text" class="form-control" name="code" :placeholder="$t('home.enterCodePlaceholder')"
          v-model="code" required @input="resetData">
        <button type="submit" class="btn button-orange">{{ $t("general.search") }}</button>
      </form>
    </div>
    <div class="homepageDivider">
      <div class="organizationalInfo">
        <div>
          <p class="highlightedText">{{ $t("home.location") }}</p>
          <span class="infoText">
            Hotel Familia<br>
            Továrenská 827<br>
            064 01 Stará Ľubovňa</span>
        </div>
        <div>
          <p class="highlightedText">{{ $t("home.date") }}</p>
          <span class="infoText">
            21.09.2024 o 15-tej hodine</span>
        </div>
      </div>
    </div>

    <div class="grid-container" v-if="guest" ref="guestSection">
      <div class="grid-item image-left">
        <img src="@/assets/images/movietape1.png" alt="Left Image">
      </div>

      <div class="grid-item form-center">

        <form class="mainForm" @submit.prevent="submitGuestForm">
          <EventInfo :role="store.getRole" />
          <div v-if="error" class="alert alert-danger" role="alert">
            {{ error }}
          </div>
          <div v-if="success" class="alert alert-success" role="alert">
            {{ success }}
          </div>
          <div v-if="info" class="alert alert-info paymentInfo" role="alert">
            {{ info }}
          </div>
          <h1 class="headingText">{{ guest.FirstName }} {{ guest.LastName }} </h1>
          <div>
            <h5 class="smallHeading">{{ $t("home.attendance") }}</h5>
            <SelectComponent :is-disabled="guest.IsPaid" :options="attendanceOptions"
              :model-value="selectedAttendanceOption" @update:model-value="updateSelectedAttendanceOptions" />
          </div>

          <div v-if="guest.Attendance === true">
            <h5 class="smallHeading">{{ $t("home.toast") }}</h5>
            <div class="styledRadioButtons">
              <input type="radio" class="btn-check" name="baverages" id="alcoholic" v-model="guest.IsBeverageAlcoholic"
                :value="true" autocomplete="off" required :disabled="guest.IsPaid">
              <label class="btn button-form" for="alcoholic">{{ $t("home.alcoholic") }}</label>

              <input type="radio" class="btn-check" name="baverages" id="non-alcoholic"
                v-model="guest.IsBeverageAlcoholic" :value="false" autocomplete="off" required :disabled="guest.IsPaid">
              <label class="btn button-form" for="non-alcoholic">{{ $t("home.nonAlcoholic") }}</label>
            </div>
          </div>

          <div v-if="guest.Attendance === true">
            <h5 class="smallHeading">{{ $t("home.food") }}</h5>
            <MultiSelect :is-disabled="guest.IsPaid" :options="allergens" :model-value="selectedAllergens"
              @update:model-value="updateSelectedOptions" />
            <div class="styledRadioButtons margin-top">
              <span v-for="dietaryPreference in dietaryPreferences" :key="dietaryPreference.ID">
                <input type="radio" class="btn-check" name="food-type" :id="dietaryPreference.Name" autocomplete="off"
                  required :disabled="guest.IsPaid" :value="dietaryPreference" v-model="guest.DietaryPreference">
                <label class="btn button-form" :for="dietaryPreference.Name">
                  {{ dietaryPreference.Name }}
                </label>
              </span>
            </div>
          </div>
          <div v-if="guest.Attendance === true">
            <h5 class="smallHeading">{{ $t("home.hotelRoomReservation") }}</h5>
            <div class="styledRadioButtons margin-top">
              <input type="radio" class="btn-check" name="hotel-room" id="hotel-room-positive" autocomplete="off"
                required :disabled="guest.IsPaid" :value="true" v-model="needHotelRoom">
              <label class="btn button-form" for="hotel-room-positive">
                {{ t('general.yes') }}
              </label>
              <input type="radio" class="btn-check" name="hotel-room" id="hotel-room-negative" autocomplete="off"
                required :disabled="guest.IsPaid" :value="false" v-model="needHotelRoom">
              <label class="btn button-form" for="hotel-room-negative">
                {{ t('general.no') }}
              </label>
            </div>
            <SelectComponent v-if="needHotelRoom" :is-disabled="guest.IsPaid" :options="hotelRooms" value="ID"
              label="label" :model-value="selectedHotelRoomOption"
              @update:model-value="updateSelectedHotelRoomOption" />
          </div>
          <div v-if="guest.Attendance === true">
            <h5 class="smallHeading">{{ $t("general.note") }}</h5>
            <textarea class="form-control styledTextarea" id="note" rows="3" :disabled="guest.IsPaid"
              v-model="guest.Note"></textarea>
          </div>
          <div class="col-12">
            <button type="submit" class="btn button-form-submit" :disabled="guest.IsPaid">{{ $t("general.save")
              }}</button>
          </div>
        </form>
      </div>
      <div class="grid-item image-right">
        <img src="@/assets/images/movietape2.png" alt="Right Image">
      </div>
    </div>
  </div>
</template>

<script>
import { getDietaryPreferences } from '@/apiCalls/DietaryPreferences';
import { getGuestByCode, updateGuestPreferencesByID } from "@/apiCalls/Guest"
import { useI18n } from 'vue-i18n';
import { getAllergens } from '@/apiCalls/Allergens';
import { getHotelRooms } from '@/apiCalls/HotelRooms';
import { useGuestSessionStore } from "@/stores/guestsessions";
import MultiSelect from '@/components/MultiSelect/MultiSelect.vue';
import SelectComponent from '@/components/Select/SelectComponent.vue';
import AppNavbar from '@/components/Navbar/AppNavbar.vue'
import EventInfo from '@/components/EventInfo/EventInfo.vue';

export default {
  name: "HomeView",
  components: {
    AppNavbar,
    SelectComponent,
    MultiSelect
  },
  data() {
    return {
      store: useGuestSessionStore(),
      t: null,
      dietaryPreferences: [],
      allergens: [],
      hotelRooms: [],
      attendanceOptions: [],
      selectedAllergens: [],
      selectedAttendanceOption: [],
      selectedHotelRoomOption: [],
      error: "",
      codeError: "",
      success: "",
      info: "",
      code: "",
      guest: null,
      needHotelRoom: false,
    };
  },
  watch: {
    guest(newGuest) {
      if (newGuest) {
        this.$nextTick(() => {
          this.scrollToGuestSection(); // Ensure the DOM is updated before calling the scroll function
        });
      }
    },
    needHotelRoom(newValue) {
      if (newValue == false) {
        this.guest.HotelRoom = null
        this.selectedHotelRoomOption = []
      }
    }
  },

  async mounted() {
    const { t } = useI18n()
    this.t = t;
    this.store.logout()
    this.attendanceOptions = [{ value: true, label: t("home.willAttend") }, { value: false, label: t("home.wontAttend") }];

    try {
      this.dietaryPreferences = await getDietaryPreferences()
      this.allergens = await getAllergens()
      this.hotelRooms = await getHotelRooms()
      this.hotelRooms.forEach((room) => {
        room.label = `${room.RoomType} - ${t("hotel.nrOfBeds")}: ${room.MaxOccupacy} (${room.Price}€)`
      });
    } catch (error) {
      this.error = error.response.data.errorMessage
      setTimeout(() => {
        this.error = ''
      }, 1500)
    }
  },
  methods: {
    updateSelectedOptions(newOptions) {
      if (newOptions) {
        this.guest.Allergens = newOptions; // Update the selected options
        this.selectedAllergens = newOptions
      } else {
        this.guest.Allergens = null
        this.selectedAllergens = [];
      }
    },

    updateSelectedAttendanceOptions(newOption) {
      if (newOption) {
        this.guest.Attendance = newOption.value; // Update the selected options
        this.selectedAttendanceOption = [newOption]
      } else {
        this.guest.Attendance = null
        this.selectedAttendanceOption = [];
      }
      this.info = this.guest.IsPaid ? this.t('alerts.paymentReceived') : ''
    },

    updateSelectedHotelRoomOption(newOption) {
      if (newOption) {
        // eslint-disable-next-line 
        this.guest.HotelRoom = (({ label, ...rest }) => rest)(newOption);
        this.selectedHotelRoomOption = [newOption];
      } else {
        this.guest.HotelRoom = null
        this.selectedHotelRoomOption = [];
      }
    },
    scrollToGuestSection() {
      const guestSection = this.$refs.guestSection; // Get the guest section element
      if (guestSection) {
        guestSection.scrollIntoView({ behavior: 'smooth' }); // Scroll smoothly to the guest section
      }
    },
    scrollToMainSection() {
      const mainSection = this.$refs.mainSection; // Get the guest section element
      if (mainSection) {
        mainSection.scrollIntoView({ behavior: 'smooth' }); // Scroll smoothly to the guest section
        setTimeout(() => {
          this.guest = ''
          this.info = ''
          this.store.logout()
        }, 1500)
      }
    },
    async resetData() {
      this.guest = '';
      this.info = '';
      this.store.logout();
    },
    async submitGuestForm() {
      try {
        if (this.needHotelRoom && this.guest.HotelRoom == null) {
          this.error = this.t('alerts.selectHotelRoomOption')
          setTimeout(() => {
            this.error = ''
          }, 1500)
          return
        }
        const guestToUpdate = {
          Attendance: this.guest.Attendance ?? null,
          Allergens: this.guest.Attendance ? this.guest.Allergens.map(allergen => allergen.ID) : [],
          DietaryPreference: this.guest.Attendance ? this.guest.DietaryPreference : null,
          HotelRoom: this.guest.Attendance ? this.guest.HotelRoom : null,
          IsBeverageAlcoholic: this.guest.Attendance ? this.guest.IsBeverageAlcoholic : null,
          Note: this.guest.Attendance ? this.guest.Note : null,
        };
        await updateGuestPreferencesByID(this.guest.ID, JSON.stringify(guestToUpdate))
        this.success = this.t('alerts.changesSaved')
        setTimeout(() => {
          this.success = ''
          this.scrollToMainSection();
        }, 1500)

      } catch (error) {
        this.error = error.response.data.errorMessage
        setTimeout(() => {
          this.error = ''
        }, 1500)
      }
    },
    async submitCode() {
      try {
        const response = await getGuestByCode(JSON.stringify({ code: this.code }))
        this.guest = response.guest
        this.info = this.guest.IsPaid ? this.t('alerts.paymentReceived') : ''
        this.code = ''
        const flattenedAllergens = this.guest.Allergens.flat();
        this.selectedAllergens = flattenedAllergens
        this.store.saveLoginState(response.token)
        if (this.guest.Attendance !== null) {
          this.selectedAttendanceOption = [{ value: this.guest.Attendance, label: this.guest.Attendance ? this.t("home.willAttend") : this.t("home.wontAttend") }]
        }
        if (this.guest.HotelRoom !== null) {
          this.needHotelRoom = true
          const room = this.guest.HotelRoom
          this.selectedHotelRoomOption = room
          this.selectedHotelRoomOption.label = `${room.RoomType} - ${this.t("hotel.nrOfBeds")}: ${room.MaxOccupacy} (${room.Price}€)`

        } else {
          this.selectedHotelRoomOption = [];
        }

      } catch (error) {
        if (error.response.data.errorMessage === 'notFound') {
          this.codeError = this.t('alerts.notFound', { entry: this.t('entry.user') })
        } else {
          this.codeError = error.response.data.errorMessage
        }
        setTimeout(() => {
          this.codeError = ''
        }, 1500)
      }
    },
  }
}
</script>